@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --background: 0 0% 100%;
        --foreground: 222.2 47.4% 11.2%;

        --muted: 210 40% 96.1%;
        --muted-foreground: 215.4 16.3% 46.9%;

        --popover: 0 0% 100%;
        --popover-foreground: 222.2 47.4% 11.2%;

        --border: 214.3 31.8% 91.4%;
        --input: 214.3 31.8% 91.4%;

        --card: 0 0% 100%;
        --card-foreground: 222.2 47.4% 11.2%;

        --primary: 222.2 47.4% 11.2%;
        --primary-foreground: 210 40% 98%;

        --secondary: 210 40% 96.1%;
        --secondary-foreground: 222.2 47.4% 11.2%;

        --accent: 210 40% 96.1%;
        --accent-foreground: 222.2 47.4% 11.2%;

        --destructive: 0 100% 50%;
        --destructive-foreground: 210 40% 98%;

        --ring: 215 20.2% 65.1%;

        --radius: 0.5rem;
    }

    .dark {
        --background: 224 71% 4%;
        --foreground: 213 31% 91%;

        --muted: 223 47% 11%;
        --muted-foreground: 215.4 16.3% 56.9%;

        --accent: 216 34% 17%;
        --accent-foreground: 210 40% 98%;

        --popover: 224 71% 4%;
        --popover-foreground: 215 20.2% 65.1%;

        --border: 216 34% 17%;
        --input: 216 34% 17%;

        --card: 224 71% 4%;
        --card-foreground: 213 31% 91%;

        --primary: 210 40% 98%;
        --primary-foreground: 222.2 47.4% 1.2%;

        --secondary: 222.2 47.4% 11.2%;
        --secondary-foreground: 210 40% 98%;

        --destructive: 0 63% 31%;
        --destructive-foreground: 210 40% 98%;

        --ring: 216 34% 17%;

        --radius: 0.5rem;
    }
}

@layer base {
    * {
        @apply border-border;
    }
    body {
        @apply bg-background text-foreground;
        font-feature-settings: "rlig" 1, "calt" 1;
    }
}


* {
    font-family: 'Cairo', sans-serif;

}






.admin-sidebar {
    background-color: #101727;
    width: 15%;
}

body {
    background-color: #f9f9f9;

}

.header {
    margin-right: 15%;
}


main {
    margin-right: 15%;
}

.success {
    background-color: #28c76f!important;

}

.success-text {
    color: #28c76f!important;
}

.error {
    background-color: #ea5455!important;
}


.error-text {
    color: #ea5455!important;
}

.pending-text {
    color: #FDB022!important;
}

.info-text {
    color: #5a8891!important;
}

.info {
    background-color: #5a8891!important;
}

.ant-btn.ant-btn-primary {
    background-color: #F4F7FD;
    border: rgba(152, 162, 179, 0.5) solid 1px;
    color: #3f3f46;
}

.ant-table-wrapper {
   box-shadow: none !important;
    border: 0px !important;
}

.ant-table {
    box-shadow: none !important;
    border: 0px !important;
}

.ant-table-container::before {
    display: none !important;
}


.ant-table-container::after {
    display: none !important;
}




