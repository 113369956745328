::-webkit-scrollbar-thumb {
    @apply bg-primary;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    @apply bg-primary;
}

::-webkit-scrollbar {
    width: 7px !important;
    height: 7px !important;
}

* {
    scroll-behavior: smooth !important;

}

.style-en {
    direction: ltr !important;
}


.empty-data img {
    @apply h-96 w-52;
}

.destination-card {
    box-shadow: 0px 1.852px 3.148px 0px rgba(0, 0, 0, 0), 0px 8.148px 6.519px 0px rgba(0, 0, 0, 0.01),
    0px 20px 13px 0px rgba(0, 0, 0, 0.01), 0px 38.519px 25.481px 0px rgba(0, 0, 0, 0.01),
    0px 64.815px 46.852px 0px rgba(0, 0, 0, 0.02), 0px 100px 80px 0px rgba(0, 0, 0, 0.02);
}

.company-slider .slick-track {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}
.company-slider .slick-slide {
    filter: grayscale(100%) !important;
}
.company-slider .slick-slide.slick-current {
    filter: grayscale(0%);
    border-radius: 15px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}

.property-shadow {
    width: 300px;
    height: 200px;
    z-index: -1;
    border-radius: 367px;
    opacity: 0.8;
    background: #59b1e6;
    filter: blur(75px);
}

.style-ar {
    direction: rtl !important;
    text-align: right !important;
}

.mirror {
    -moz-transform: scale(-1, 1) !important;
    -webkit-transform: scale(-1, 1)  !important;
    -o-transform: scale(-1, 1)  !important;
    -ms-transform: scale(-1, 1)  !important;
    transform: scale(-1, 1)  !important;
}

.slick-slider slick-initialized {
    direction: ltr !important;
}